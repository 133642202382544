$(function(){
    initMobileMenu();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();
    initSearchBarV2();


    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    function initSearchBarV2() {
        $('#search-btn').click(function(){
            var $searchBar = $('#search-bar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();

    upperHeaderReAdjustment();

    imgToBG($('.bg-img'), '.img-bg');

    $("#footer-button, .back-to-top").click(function () {
        $("html, body").animate({scrollTop: $("#wrapper").offset().top}, "1000");
        return false;
    });
});

function imgToBG(ele, _imgClass){
    if(ele.length > 0){
        ele.each(function(){
            var _this = $(this), img = _this.find(_imgClass).eq(0) || _this.find('img').eq(0);
            _this.css({
                'background': 'url("'+img.attr('src')+'")', 'background-repeat':'no-repeat'
            });
            img.hide();
        });
    }
}

function upperHeaderReAdjustment() {
    $('#random-logo-width').css({
        'width' : $('#random-logo').outerWidth(false) + 'px',
        'display' : 'inline-block'
    });
    $(window).resize(function() {
        $('#random-logo-width').css({
            'width' : $('#random-logo').outerWidth(false) + 'px',
            'display' : 'inline-block'
        });
    });
};

function initMobileMenu() {
    var menu = $('#menu nav.mobile').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "right"
        }
    });
}
